var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentUser.superAdmin)?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.donationGoals,"options":_vm.pagination,"server-items-length":_vm.totalDonationGoals,"loading":_vm.loading,"footer-props":{
      'showFirstLastPage':true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.bannerUrl",fn:function(ref){
    var item = ref.item;
return [_c('v-img',{attrs:{"src":item.bannerUrl,"contain":"","max-height":"150","max-width":"150"},on:{"error":function($event){item.bannerUrl = 'media/error/imageNotFound.png'}}})]}},{key:"item.globalGoal",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.globalGoal != null ? item.globalGoal.title : "")+" ")]}},{key:"item.clear",fn:function(ref){
    var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('router-link',{attrs:{"to":{
            name: 'DonationGoalEdit',
            params: { donationGoalId: item.id }
          }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)],1)]}},{key:"item.add",fn:function(ref){
          var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"red darken-4","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.delRecord(item)}}},[_vm._v("mdi-delete")])],1)]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-avatar',{attrs:{"color":"indigo","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.retrieveDonationGoals();}}},[_vm._v("mdi-magnify ")])],1)],1),_c('td',[_c('v-avatar',{attrs:{"color":"grey","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.clearSearch();}}},[_vm._v("mdi-close ")])],1)],1),_c('td',[_c('router-link',{attrs:{"to":{ name: 'DonationGoalCreate' }}},[_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1)],1)])]},proxy:true}],null,false,3801692815)}),_c('ConfirmDialog',{ref:"confirm"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }